import HTTP from "./http-common";

class MessageService {
  getMessage(msgId) {
    return HTTP.get("message/getmessage/" + msgId);
  }
  
  getUnReadMsg() {
    return HTTP.get("message/getunreadmsg");
  }

  async getOrderMsg(orderId) {
    return await HTTP.get("message/getordermsg/"+ orderId);
  }

  getSendMsg() {
    return HTTP.get("message/getsendmsg");
  }

  getDeletedMsg() {
    return HTTP.get("message/getdeletedmsg");
  }

  sendMessage(data) {
    return HTTP.post("message/sendmessage",data);
  }

  deleteMessage(msgId){
    return HTTP.post("message/deletemessage/"+ msgId);
  }

}

export default new MessageService();
