export default {
    //api_url: 'https://localhost:44325/api/',
    //api_url: 'http://10.0.0.248:96/api/',
    api_url: 'http://78.189.109.240:96/api/',
    dxgrid_header_color : '#EEEEEE',
    firma_file_max_size : 10485760, //10 mb
    slip_file_max_size : 10485760,
    delivery_date_past_color : '#F58A88',
    message_check : 300000 //5 dk


 }