<template>
    <v-app>
        <v-container fluid fill-height id="login-page">
            <v-layout align-center justify-center>
                <v-flex :style="{ 'max-width': '360px', 'margin': '60px auto 20px auto;' }">
                    <v-card outlined>
                        <v-card-text>
                            <v-alert v-if="message" type="warning" outlined>
                                {{ message }}</v-alert>

                            <div class="portal-logo">
                                <img src="../../assets/logo.png" />
                            </div>


                            <h3 class="portal-title">Tedarikçi Portalı</h3>


                            <v-divider></v-divider>

                            <form class="mt-5" @submit="handleLogin">
                                <DxForm :form-data="userLogin" :read-only="false" :show-colon-after-label="false"
                                    id="lform" labelMode='hidden' @editor-enter-key="handleLogin"
                                    :show-validation-summary="true" validation-group="userLoginData">

                                    <DxSimpleItem data-field="usercode" :editor-options="usercodeoption">
                                        <DxRequiredRule message="Lütfen kullanıcı adını giriniz !" />
                                    </DxSimpleItem>



                                    <DxSimpleItem :editor-options="passwordOptions" data-field="password"
                                        editor-type="dxTextBox">
                                        <DxRequiredRule message="Lütfen şifre giriniz !" />
                                    </DxSimpleItem>



                                </DxForm>


                            </form>



                        </v-card-text>


                        <v-card-actions class="mr-2 mb-5 ml-2">

                            <DxButton text="Şifremi Unuttum" type="primary" styling-mode="contained" :height="36"
                                horizontal-alignment="right" @click="forgotPassword" />

                            <v-spacer></v-spacer>

                            <DxButton text="Oturum Aç" type="success" styling-mode="outlined" :width="150" :height="36"
                                horizontal-alignment="right" @click="handleLogin">
                                <template #content>
                                    <span>
                                        <DxLoadIndicator :visible="loading" class="button-indicator" />
                                        <span class="dx-button-text">Oturum Aç</span>
                                    </span>
                                </template>
                            </DxButton>

                        </v-card-actions>
                    </v-card>

                    <div class="reklam">
                        İmortaş YBS 2022 (0.4.1)
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>


</template>
<script>
import validationEngine from 'devextreme/ui/validation_engine';
import DxButton from 'devextreme-vue/button';
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
import DxForm, {
    DxSimpleItem,
    DxRequiredRule,
} from 'devextreme-vue/form';

export default {
    components: {
        DxSimpleItem,
        DxRequiredRule,
        DxForm,
        DxButton,
        DxLoadIndicator
    },
    name: "Login",
    data() {
        return {

            userLogin: {
                usercode: ''
            },
            loading: false,
            message: "",
            usercodeoption: {
                placeholder: 'Kullanıcı Adı',
            },
            passwordOptions: {
                mode: 'password',
                placeholder: 'Şifre',

            },
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        }
    },
    methods: {

        handleLogin() {

            let valResult = validationEngine.validateGroup('userLoginData');
            if (!valResult.isValid) return;

            this.loading = true;

            this.$store.dispatch("auth/login", this.userLogin).then(
                (response) => {

                    if (response.Status) {
                        /* ROLLER YÜKLE */
                        this.$store.dispatch('role/fillRoleList');
                        this.$store.dispatch("message/setMgsCount");



                        this.$router.push('/').catch((e) => {
                            this.loading = false;
                            this.message = e.message;
                        });
                    }
                    else {
                        this.loading = false;
                        this.message = response.Message;
                    }


                },
                (error) => {

                    this.loading = false;
                    this.message = error.message.toString();

                }
            );



        },

        forgotPassword() {
            this.$router.push("/forgotpassword");

        }
    }
};
</script>

<style scoped>
#login-page {
    background-color: #333333;
    /* #ECEFF1;*/
}

::v-deep .button-indicator {
    height: 32px;
    width: 32px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

/* .no-uppercase {
    text-transform: unset !important;
} */


.portal-title {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 25px;
    color: #000;
}

.portal-logo {
    padding: 15px;
    text-align: center;
    margin: 60px auto 20px auto;
}

.reklam {
    text-align: center;
    color: aliceblue;
    font-family: "Open Sans", sans-serif;
    margin-top: 5px;
}
</style>
