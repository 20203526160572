import axios from "axios";
import config from "@/config.js";
import store from "@/store";
import router from "@/router";

const HTTP = axios.create({
  baseURL: config.api_url,
});

HTTP.CancelToken = axios.CancelToken;
HTTP.isCancel = axios.isCancel;

HTTP.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      config.headers["Authorization"] = `Bearer ${user.Token}`;
      config.headers["Content-type"] = `application/json; charset=utf-8`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errcode = error.response.status;

    if (errcode == 401) {
      store.dispatch("auth/logout");
      router.push("/login");
    }
    else if (errcode == 404 || errcode == 403)
    router.push("/pagenotfound");
    else{
      console.log(error);
      return Promise.reject(error);
    }
    
  }
);

export default HTTP;
