<template>
  <v-navigation-drawer :value="drawer" app dark>
    
    <v-list-item>
      <v-img max-height="50" max-width="60" src="../../src/assets/sidebar_logo.png"></v-img>
      <v-list-item-content>
        <v-list-item-title class="custom-app-title"> İmortaş</v-list-item-title>
        <v-list-item-subtitle>
          Tedarikçi Portalı
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    
    <v-list dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="custom-sidebar-title">Anasayfa</v-list-item-title>
      </v-list-item>



      <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action>

        <template v-slot:activator>

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="custom-sidebar-title" v-text="item.title" :to="item.to"></v-list-item-title>
          </v-list-item-content>

        </template>

        <v-list-item v-for="child in item.items" :key="child.title" :to="child.to" link>
          <v-list-item-content>
            <v-list-item-title class="custom-sidebar-elements" v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: { drawer: Boolean },
  data: () => ({
    items: [],
    right: null,
  }),

  mounted(){
    this.items = this.$store.state.auth.user.NavMenu;
  }
};
</script>
<style scoped>
.custom-sidebar-title {
  color: #c3c3c3;
  font-weight: 500;
  font-size: 3rem;
  font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
}

.custom-sidebar-elements {
  color: rgba(255, 255, 255, 255);
  font-weight: 500;
  font-size: 1.25rem;
  font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
}
</style>

