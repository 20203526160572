import UserService from "../services/user.service";

const initialState = {
  isSupplierAdmin: false,
  isImortasUser : false,
  firmaId : 0,
  roleList: [],
};

export const role = {
  namespaced: true,
  state: initialState,
  actions: {
    fillRoleList({ commit }) {
      UserService.getuserroles(0).then(
        (response) => {
          commit("setRoleList", response.data);

          /* IS FIRMA ADMIN */
          var res = response.data.filter((x) => x.NAME == "SupplierAdmin");       
          if (res.length > 0) {
            commit("setFirmAdmin", true);
          } else {
            commit("setFirmAdmin", false);
          }

          /* IS USER IMORTAS USER*/
          var res2 = response.data.filter((x) => x.NAME == "ImortasUser");
          if (res2.length > 0) 
            commit("setImortasUser", true);
          else commit("setImortasUser", false);

        
        },
       
      );
    },

    
    
  },
  mutations: {
    setFirmAdmin(state, payload) {
      state.isSupplierAdmin = payload;
    },
    setImortasUser(state, payload) {
        state.isImortasUser = payload;
      },
    setRoleList(state, payload) {
      state.roleList = payload;
    },
  },

  getters: {
    isUserFirmAdmin: (state) => {
      return state.isSupplierAdmin;
    },
    isImortasUser: (state) => {
        return state.isImortasUser;
      },
    getUserRoles: (state) => {
      return state.roleList;
    },
  },
};
