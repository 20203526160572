import MessageService from "../services/message.service";
import notify from "devextreme/ui/notify";

const initialState = {
  msgCount: 0,
  unreadMsg : [],
  orderMsg:[]
};

export const message = {
  namespaced: true,
  state: initialState,
  actions: {
    
    setMgsCount({ commit }) {
      MessageService.getUnReadMsg().then((response) => {
        const msg = response.data.Data; 
        const len = response.data.Data.length;
        if (len > 0) notify("Yeni mesajınız var", "info", 2000);
        commit("setMsgCount", len);
        commit("setUnreadMsg", msg);

      });
    },

    async  getOrderMsg({ commit }, orderId)
    {
      
      await MessageService.getOrderMsg(orderId).then((response) => {
        const msg = response.data.Data; 
        commit("setOrderMsg", msg);

      });
    }
  },

  mutations: {
    setMsgCount(state, payload) {
      state.msgCount = payload;
    },
    setUnreadMsg(state, payload) {
      state.unreadMsg = payload;
    },
    setOrderMsg(state ,payload){
      state.orderMsg = payload;
    }
  },
};
