import Vue from "vue";
import VueRouter from "vue-router";
import MainLayout from "../layouts/MainLayout";
import Login from "../views/auth/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/home",
    component: MainLayout,
    children: [
      
      {
        path: "/home",
        name: "home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../views/user/Profile.vue"),
      },
      {
        path: "/pagenotfound",
        name: "pagenotfound",
        component: () => import("../views/auth/PageNotFound.vue"),
      },

      /* Firma */
      {
        path: "/firma",
        name: "firmalist",
        component: () => import("../views/firma/FirmaList.vue"),
      },
      {
        path: "/firma/:id",
        name: "firmaedit",
        component: () => import("../views/firma/FirmaEdit.vue"),
        props: true,
      },

      /* User */
      {
        path: "/users",
        name: "userlist",
        component: () => import("../views/user/UserList.vue"),
      },
      {
        path: "/user/:id",
        name: "useredit",
        component: () => import("../views/user/UserEdit.vue"),
        props: true,
      },

      /* Siparişler */

      {
        path: "/orders",
        name: "orders",
        component: () => import("../views/firmaorder/OrderList.vue"),
      },

      /* İrsaliyeler */

      {
        path: "/slips",
        name: "slips",
        component: () => import("../views/firmaslip/SlipList.vue"),
      },
      {
        path: "/slip/:id",
        name: "slipedit",
        component: () => import("../views/firmaslip/SlipEdit.vue"),
        props: true,
      },
      // {
      //   path: "/newslip",
      //   name: "newslip",
      //   component: () => import("../views/firmaslip/SlipNew.vue"),
      // },

      /* MESAJ */
      {
        path: "/inbox",
        name: "inbox",
        component: () => import("../views/message/Inbox.vue"),
      },
     
      
    ],
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    component: () => import("../views/auth/ForgotPwd.vue"),
  },
  {
    path: "/changepasswordbykey",
    name: "changepasswordbykey",
    component: () => import("../views/auth/ChangePwdByKey.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Login,
  },
  
  
   { path: '/:pathMatch(.*)*', 
     component: () => import("../views/auth/PageNotFound.vue" )
   }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login","/changepasswordbykey","/forgotpassword"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("user"));
  
  
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
