import HTTP from './http-common';

class AuthService {
  login(userLogin) {
    return HTTP
      .post('auth/login', {
          usercode: userLogin.usercode,
          password: userLogin.password
      })
      .then(response => {
       
        if (response.data.Status) {
          localStorage.setItem('user', JSON.stringify(response.data.Data));
        
        }

        return response.data;
      })
     
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.clear()
  }

  forgotPassword(userCode){
    return HTTP.post("auth/forgotpassword/"+ userCode);
  }

  changePwd(data){
    return HTTP.post("auth/changepasswordbykey/", data);
  }

 
}

export default new AuthService();