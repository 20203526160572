export default class User {
    constructor(ID,USERCODE, USERNAME, EMAIL,STATUS,CLIENTREF,FIRMANAME,NAVMENU) {
      this.ID = ID,
      this.USERCODE = USERCODE,
      this.USERNAME = USERNAME,
      this.EMAIL = EMAIL,
      this.STATUS = STATUS,
      this.CLIENTREF= CLIENTREF,
      this.FIRMANAME = FIRMANAME,
      this.NAVMENU = NAVMENU;
      
    }
  }