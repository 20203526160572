import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VeeValidate from 'vee-validate';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment'


Vue.config.productionTip = false
Vue.use(VeeValidate);


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
});

new Vue({
  vuetify,
  router,
  store,

  render: h => h(App)
}).$mount('#app')
