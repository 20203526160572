<template>
  <v-app>
    <router-view />

  </v-app>
</template>

<script>


import trMessages from 'devextreme/localization/messages/tr.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from '@/config'

export default {

  name: 'App',
  components: {

  },
  mounted() {
    window.setInterval(() => {
      this.$store.dispatch("message/setMgsCount")
    }, config.message_check);
  },
  created() {


    this.locale = this.getLocale();
    this.initMessages();
    locale(this.locale);


    const user = JSON.parse(localStorage.getItem("user"));

    if (user !== null) {
      let expirationDate = new Date(user.Expiration).toISOString();
      let time = new Date().toISOString();

      if (time >= expirationDate) {

        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
        return;
      }

      this.$store.dispatch('role/fillRoleList');
      this.$store.dispatch("message/setMgsCount");


    }





  },

  methods: {
    getLocale() {
      const storageLocale = sessionStorage.getItem('locale');
      return storageLocale != null ? storageLocale : 'tr';
    },

    initMessages() {
      loadMessages(trMessages);
    },


  },

};
</script>



<style>
::v-deep .dx-button.dx-button-has-icon .dx-button-content {
  padding-top: 0, 1px;
}

::v-deep .custom-card-title {
  /* color: rgba(0, 0, 0, 0.6);*/
  color: #ca0505;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;


}



.custom-app-title {
  color: #fff;
  /*rgba(255, 255, 255, 255);*/
  font-weight: 400;
  font-size: 1.51rem;
  font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;

}

.yellow-textbox {
  background-color: yellow;
}

::v-deep .dxcolumnbg {
  background-color: rgb(221, 41, 50) !important;
}

/* vuetify büyük harfi küçük harfe çevir*/
.no-uppercase {
  text-transform: unset !important;
}
</style>
