<template>
  <v-app id="inspire">
    <Sidebar :drawer="drawer"></Sidebar>

    <v-app-bar tile app absolute color="white" elevation="0">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-badge color="error" v-if="getmsgCount" :content="getmsgCount">
        <v-icon color="blue darken-2" @click="openInbox">mdi-email</v-icon>
      </v-badge>
      <v-icon v-if="!getmsgCount" color="blue darken-2" @click="openInbox">mdi-email</v-icon>
      <v-spacer></v-spacer>

      <v-menu open-on-hover top offset-y>

        <template v-slot:activator="{ on, attrs }">




          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="success">mdi-account</v-icon>
          </v-btn>

          <div class="row">
            <p class="blue--text">{{ currentUser.UserName }}</p>
            <p>{{ currentUser.FirmaName }}</p>
          </div>
        </template>


        <v-list class="menucls">
          <v-list-item v-for="(item, index) in items" :key="index" link>
            <v-list-item-title :id="index" @click="topMenuClick" :aselectedKey="index" ref="select">
              <v-icon color="primary" v-text="item.icon"></v-icon> {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar>

    <v-main class="blue-grey lighten-5">
      <router-view class="pa-5"></router-view>

    </v-main>
  </v-app>
</template>

<script>

import Sidebar from "../layouts/Sidebar.vue";


export default {
  components: { Sidebar },
  data: () => ({
    drawer: false,
    items: [{ title: "Profil", icon: 'mdi-account' },

    { title: "Çıkış", icon: 'mdi-exit-to-app' }],

  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getmsgCount() {
      return this.$store.state.message.msgCount;
    }

  },

  methods: {
    openInbox() {
      if (this.$route.path !== '/inbox')
        this.$router.push("/inbox");

    },
    topMenuClick(e) {

      if (e.target.id == 0) {
        if (this.$route.path !== '/profile')
          this.$router.push("/profile");
      }
      // else if (e.target.id == 1) {
      //   if (this.$route.path !== '/inbox')
      //     this.$router.push("/inbox");
      // }
      else {

        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      }

    },

  },

};
</script>
<style scoped>
.row {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  line-height: 1px;
  margin-top: 1px;
  margin-right: 1px;
  font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;

  @media only screen and (max-device-width: 640px) {
    font-size: 10px;
  }



}

.menucls {
  padding-right: 5px;
  width: 150px;
}
</style>

