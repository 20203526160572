import HTTP from './http-common';
import User from '../models/user';


class UserService {
    
  get(id) { 
    return HTTP.get('user/' + id); 
  }

  update(data) {
    return HTTP.post('user/update/' + data.ID,  data);
  }

  insert(data) {
    return HTTP.post('user/insert/',  data);
  }

  delete(data) {
    //console.log(data);
    return HTTP.post('user/delete/'+ data);
  }

  checkpwd(data) {
    return HTTP.post('user/checkpwd/', JSON.stringify(data));
  }

  changepwd(data) {
    return HTTP.post('user/changepwd/', JSON.stringify(data));
  }

  getuserroles(userId) { 
    return HTTP.get('user/getuserroles/'+  userId); 
  }

  getUserList() { 
    return HTTP.get('user/getuserlist/'); 
  }

 

  addrole(data) {
    return HTTP.post('user/addrole/',  JSON.stringify(data) );
  }
  
  
  deleterole(data) {
    return HTTP.post('user/deleterole/',  JSON.stringify(data)  );
  }

  approveConsent() {
    return HTTP.post('user/approveconsent/');
  }

  resetpwd(data) {
    return HTTP.post('user/resetpassword/', JSON.stringify(data));
  }

   BlankModel(){

    var blankModelData = new User();
    blankModelData.ID = 0;
    blankModelData.USERCODE = "";
    blankModelData.USERNAME = "";
    blankModelData.EMAIL = "";
    blankModelData.STATUS = true;
    blankModelData.CLIENTREF="";
    blankModelData.FIRMANAME = "";
    return blankModelData;

   }
   


}

export default new UserService();


